@import "styles/_variables.scss";

.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 40px;

  &.dark {
    color: white;
  }

  &__main {
    background: $faqGreyHoverColor;
    border-radius: 8px;
    padding: 20px;
    width: 270px;

    &.dark {
      background: $lightDarkBg;
    }
  }

  &__title {
    &__lg {
      font-size: 20px;
      line-height: 32px
    }

    &__md {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 8px 0 20px;
  }

  &__link {
    color: $footerBackground;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;

    &.dark {
      color: $greenDarkModeHighlight;
    }

    & div {
      transition: all .1s linear;
    }

    &:hover div {
      margin-left: 2px;
    }
  }

  &__list {
    display: flex;
    width: 790px;
    gap: 32px 40px;
    flex-wrap: wrap;
  }

  &__default {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 270px;

    &_icon, &_icon div {
      height: 24px;
    }

    &:hover &_icon svg path {
      fill: $footerBackground;
    }

    &:hover &_icon.dark svg path {
      fill: $greenDarkModeHighlight;
    }

    &:hover &_title {
      color: $footerBackground;
    }

    &:hover &_title.dark {
      color: $greenDarkModeHighlight;
    }

    &:hover &_text {
      color: $footerBackground;
    }

    &:hover &_text.dark {
      color: $greenDarkModeHighlight;
    }
  }

  &__image {
    overflow: auto;
    position: relative;
    height: 138px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

.icon.dark path {
  fill: $greenDarkModeHighlight;
}
