@import "styles/_variables.scss";

.footer {
  background: $footerBackground;
  color: $white;
  font-family: $fontFamily;
  width: 100%;
  z-index: 1;

  &.dark {
    background: $black;
    border-top: 1px solid $mainBlack;
  }

  &__wrapper {
    padding: 80px 0 40px;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;

    @media (max-width: $tabletPoint) {
      max-width: unset;
      padding: 56px 22px 32px;
    }

    @media (max-width: $mobilePoint) {
      padding-top: 48px;
    }
  }

  &__logo {
    @media (max-width: $mobilePoint) {
      grid-column: 1 / -1;
    }
  }

  &__navigation {
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    padding-bottom: 32px;
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;

    @media (max-width: $tabletPoint) {
      padding-bottom: 32px;
      margin-bottom: 32px;
    }

    @media (max-width: $mobilePoint) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 40px;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__link {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    opacity: .85;

    &.pt {
      padding-top: 24px;
    }

    &.bold {
      font-weight: 600;
      opacity: 1;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $mobilePoint) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__rights {
    font-weight: 500;
    font-size: 12px;
    opacity: .65;
  }

  &__follow {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
