@import "styles/_variables.scss";


.header {
    position: fixed;
    height: 80px;
    width: 100%;
    background: $whiteBackground;
    z-index: 15;
    box-shadow: 0px 1px 0px 0px $bloghrColor;

    &.dark {
        background-color: $black;
        box-shadow: 0px 1px 0px 0px $blackShadow;
        color: $white;

        & .button {
            background-color: $white;
            border-color: $white;
            color: $black;

            &__burger, &__burger::before, &__burger::after {
                background-color: $white;
            }

            &__burger__block_active {
                .button__burger {
                    background: transparent;
                }
            }
        }

        & .navigation {
            background-color: $black;
        }
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.container {
    margin: 0 auto;
    max-width: $middleDesktopContainer;
    display: flex;
    height: 100%;
    align-items: center;

    @media(max-width: $tabletPoint) {
        max-width: 100%;
        padding: 0 22px;
        justify-content: space-between;
    }
}

.flex {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.button {
    display: inline-block;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid $greenBackground;
    background-color: $greenBackground;
    height: 40px;
    color: $white;
    font-family: $fontFamily;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    transition: color .3s linear, background-color .3s linear;
    padding: 11px 16px 12px 16px;
    min-width: 128px;

    &_hidden {
        display: none;
    }

    &:hover {
        cursor: pointer;
        color: $greenBackground;
        background-color: $white;
    }

    &__burger {
        width: 20px;
        height: 2px;
        outline: none;
        border: none;
        background-color: $mainText;
        border-radius: 1px;
        transition: all .5s;

        &::after,
        &::before {
            content: '';
            position: absolute;
            width: 20px;
            height: 2px;
            background-color: $mainText;
            border-radius: 1px;
            transition: all 1s;
        }

        &::before {
            transform: translateY(-5px);
        }

        &::after {
            transform: translateY(5px);
        }

        &__block {
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            margin-left: 20px;
            display: none;

            &:hover {
                cursor: pointer;
            }

            @media(max-width: $tabletPoint) {
                display: flex;
            }

            &_active {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 40px;
                height: 40px;
                margin-left: 20px;

                &:hover {
                    cursor: pointer;
                }

                .button__burger {
                    transform: translateX(-20px);
                    background: transparent;
                }

                .button__burger::before {
                    transform: rotate(45deg) translate(15px, -15px);
                }

                .button__burger::after {
                    transform: rotate(-45deg) translate(15px, 15px);
                }
            }
        }
    }
}

.navigation {
    margin: 0 auto;

    @media(max-width: $tabletPoint) {
        display: none;
    }
}

.logo {
    &_mobile {
        display: none;

        @media(max-width: $mobilePoint) {
            display: block;
        }
    }

    @media(max-width: $mobilePoint) {
        display: none;
    }
}
