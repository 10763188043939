@import "styles/_variables.scss";

$maxContainerHeight: 822px;

.layout {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: 0.8s;
  animation-name: fadeIn;
  z-index: 10000;
  background-color: $formBackground;
  overflow-y: scroll;
  overflow-x: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 640px;
  height: $maxContainerHeight;
  background-color: $inputsText;
  border-radius: 12px;
  overflow-x: hidden;
  animation-duration: 0.8s;
  animation-name: slide;
}

@media (max-height: $maxContainerHeight) {
  .container {
    height: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@keyframes slide{
  from{
      transform: translateY(-550px);
  }
  to{
      transform: translateY(0px);

  }
}
