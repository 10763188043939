@import "styles/_variables.scss";

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  &__title {
    &__lg {
      font-size: 20px;
      line-height: 32px
    }

    &__md {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__default {
    &_title {
      color: $blogSecondText;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 8px 0 20px;
  }

  &__link {
    color: $footerBackground;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 26px;
    gap: 12px;

    &.dark {
      color: $greenDarkModeHighlight;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid $bloghrColor;
    padding-bottom: 8px;
  }

  &__body {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    display: flex;
    align-items: center;
    gap: 12px;

    & div, & div div {
      height: 24px;
      width: 24px;
    }

    &:hover {
      color: $footerBackground;

      & svg path {
        fill: $footerBackground;
      }

      &.dark:hover {
        color: $greenDarkModeHighlight;

        & svg path {
          fill: $greenDarkModeHighlight;
        }
      }
    }
  }

  &__default {
    width: 100%;

    &:first-of-type {
      margin-top: 24px;
    }
  }

  &__button {
    width: 100%;
    margin-top: 8px;
    color: $footerBackground;
    border: 1px solid $footerBackground;
    border-radius: 8px;
    padding: 11px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    transition: all .3s linear;

    &.dark {
      color: $greenDarkModeHighlight;
      border-color: $greenDarkModeHighlight;

      &:hover {
        background: $greenDarkModeHighlight;
      }
    }

    &:hover {
      background: $footerBackground;
      color: $white;
    }
  }
}

.icon.dark path {
  fill: $greenDarkModeHighlight;
}
