@import "styles/_variables.scss";

.container {
  cursor: default;
  transition: all .3s linear;
  border-top: 1px solid $bloghrColor;
  background: $whiteBackground;
  font-family: $fontFamily;
  color: $mainBlack;
  box-sizing: border-box;
  min-height: 178px;
  padding: 32px 0;
  position: fixed;
  top: 80px;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  &.dark {
    background: $black;
    border-top-color: $blackShadow;
  }
}

.overlay {
  position: fixed;
  top: 81px;
  right: 0;
  left: 0;
  bottom: 0;
  background: black;
  opacity: 0.3;
  pointer-events: none;
  z-index: -1;
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}


@keyframes show {
  from {
    opacity: 0;
  }
  to {
   opacity: 1;
  }
}

@keyframes overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
}
