@import "styles/_variables.scss";

.item {
    &.dark {
        & .item__link {
             color: $white;

            &_active,
            &:hover {
                color: $grayColor;
            }
        }
    }

    padding: 0 10px;
    position: relative;
    display: flex;
    align-items: baseline;
    gap: 6px;
    cursor: pointer;

    &__icon {
        transform: rotate(180deg);
        transition: transform .3s linear;

        &_active {
            transform: rotate(0deg);
            position: relative;
            top: 2px;
        }

        &.dark {
            filter: invert(100%);
        }
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &__link {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        color: $mainText;
        height: 51px;
        display: flex;
        align-items: center;
        height: 100%;

        &_active {
            color: $greenBackground;
        }

        &:hover {
            color: $greenBackground;

            .list__wrap {
                transform: scaleY(1);
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        padding: 12px;
        background: $white;
        border-radius: 4px;
        width: 220px;
        box-shadow: 0px 6px 40px rgba(29, 29, 31, 0.08);
    }
}

.list {
    &__wrap {
        position: absolute;
        top: 100%;
        transform: scaleY(0);
        left: -34%;
        transform-origin: 0 0;
        transition: transform 0.2s ease-in-out;
        width: 100%;
    }

    &__item {
        color: $mainText;
        display: flex;
        align-items: center;
        padding: 14px 12px;
        border-radius: 4px;
        transition: color 0.2s linear, background 0.2s linear, fill .2s linear;

        &_active {
            background: #E3EAE6;

            .list__item__name {
                color: $greenBackground;
            }

            svg {
                path {
                    fill: $greenBackground;
                }
            }
        }

        &:hover {
            background: #E3EAE6;

            .list__item__name {
                color: $greenBackground;
            }

            svg {
                path {
                    fill: $greenBackground;
                }
            }
        }

        &__image {
            margin-right: 16px;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
        }

        &__name {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 17px;
            color: $mainText;
        }
    }
}
