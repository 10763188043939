@import "styles/_variables.scss";

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $whiteBackground; // Default color which is used to cover the navigation which is behind. Custom pages can easily override this.
}

// Deprecated flex container which we decided to remove.
//.container {
//  display: flex;
//  flex: 1;
//  background-color: $white; // Default color which is used to cover the navigation which is behind. Custom pages can easily override this.
//}

.main {
  flex: 1;
  padding-top: $headerHeight;
  transition: transform .3s ease;
}
