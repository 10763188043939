@import "styles/_variables.scss";

.cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding-top: 20px;

  &__title {
    &__lg {
      font-size: 20px;
      line-height: 32px
    }

    &__md {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 8px 0 20px;
  }

  &__link {
    color: $footerBackground;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 16px;
    line-height: 26px;

    &.dark {
      color: $greenDarkModeHighlight;
    }
  }

  &__default {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    &_icon, &_icon div {
      height: 24px;
    }

    &:hover &_icon svg path {
      fill: $footerBackground;
    }

    &:hover &_icon.dark svg path {
      fill: $greenDarkModeHighlight;
    }

    &:hover &_title {
      color: $footerBackground;
    }

    &:hover &_title.dark {
      color: $greenDarkModeHighlight;
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  &__button {
    margin-top: 8px;
    color: $footerBackground;
    border: 1px solid $footerBackground;
    border-radius: 8px;
    padding: 11px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    transition: all .3s linear;

    &.dark {
      color: $greenDarkModeHighlight;
      border-color: $greenDarkModeHighlight;

      &:hover {
        background: $greenDarkModeHighlight;
      }
    }

    &:hover {
      background: $footerBackground;
      color: $white;
    }
  }
}

.icon.dark path {
  fill: $greenDarkModeHighlight;
}
