@import "styles/_variables.scss";

.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 40px;

  &.dark {
    color: white;
  }

  &__main {
    background: $faqGreyHoverColor;
    border-radius: 8px;
    padding: 20px;
    height: min-content;
    width: 270px;

    &.dark {
      background: $lightDarkBg;
    }
  }

  &__title {
    &__lg {
      font-size: 20px;
      line-height: 32px
    }

    &__md {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 8px 0 20px;
  }

  &__link {
    color: $footerBackground;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;

    &.dark {
      color: $greenDarkModeHighlight;
    }

    & div {
      transition: all .1s linear;
    }

    &:hover div {
      margin-left: 2px;
    }
  }

  &__default {
    &_title {
      color: $blogSecondText;
    }
  }

  &__body {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__text {
    display: flex;
    gap: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;

    &:hover {
      color: $footerBackground;

      & svg path {
        fill: $footerBackground;
      }

      &.dark:hover {
        color: $greenDarkModeHighlight;

        & svg path {
          fill: $greenDarkModeHighlight;
        }
      }
    }
  }
}

.icon {
  filter: grayscale(100%) brightness(0%);
  display: none;

  &.dark {
    filter: unset;

    & path {
      fill: $greenDarkModeHighlight;
    }
  }
}

.learn_more.dark path {
  fill: $greenDarkModeHighlight;
}
