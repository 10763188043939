@import "styles/_variables.scss";

.spinner {
    width: 1.5rem;
    height: 1.5rem;
    border-top-color: #444;
    border-left-color: #444;

    animation: spinner 400ms linear infinite;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
}

.spinner_large {
    width: 5rem;
    height: 5rem;
    border-width: 6px;
}

.spinner_slow {
    animation: spinner 1s linear infinite;
}

.spinner_green {
    border-top-color: $greenBackground;
    border-left-color: $greenBackground;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.form__preloader {
    width: 100%;
    height: 100vh;

    &_active {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &_hidden {
        display: none;
    }
}