@import "styles/_variables.scss";

.detail__form__block {
  display: flex;
  flex-direction: column;
  &_hidden{
    display: none;
  }
}

.detail__form__item {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  position: relative;

  :global{
    .detail__form__input {
      border: 1px solid $bloghrColor !important;
      background-color: $white !important;
      box-sizing: border-box !important;
      border-radius: 5px !important;
      width: 100% !important;
      height: 49px !important;
      color: $blogSecondText !important;
      font-family: $fontFamily !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      letter-spacing: 0 !important;
      line-height: 17px !important;
      padding-left: 100px !important;

      &:focus {
        border: 1px solid #024D40 !important;

        & + .detail__form__btn {
          border: 1px solid #024D40 !important;
        }
      }
    }

    .detail__form__btn {
      background-color: #fff !important;

      & .selected-flag {
        padding: 0 0 0 20px !important;
        width: 84px !important;
      }

      & .flag {
        scale: 1.5;

        & .arrow {
          background-image: url(/assets/icons/icn-arrow.svg);
          background-size: contain;
          width: 16px;
          height: 9px;
          border: 0;
          background-repeat: no-repeat;
          scale: 0.6666;
          margin-top: -5px;
          left: 22px;
          transition: all 0.3s linear;
          opacity: 0.7;
        }

        & .up {
          transform: rotate(-180deg);
          border-bottom: none !important;
        }
      }
    }

    .detail__form__dropdown {
      border: 1px solid #DFDEDF !important;
      box-shadow: 0 6px 40px rgba(29, 29, 31, 0.08) !important;
      border-radius: 4px !important;
      padding: 8px 0 !important;

      & .country-name {
        font-size: 16px;
        line-height: 26px;
        color: #1D1D1F;
        margin: 0 8px 0 12px;
      }

      & .country {
        padding: 7px 9px 7px 19px !important;

        &.highlight,
        &:hover {
          background-color: #F5F3F0 !important;
        }
      }
    }
  }
}

.detail__form__item_message {
  height: 173px;
}

.detail__form__item:last-of-type {
  margin: 16px 0 28px 0;
}

.input__title {
  color: $mainBlack;
  font-family: $fontFamily;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 12px;
}

.detail__form__input {
  border: 1px solid $bloghrColor;
  background-color: $white;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 48px;
  color: $blogSecondText;
  font-family: $fontFamily;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  padding-left: 16px;
  outline: none;

  &:focus {
    border: 1px solid $greenBackground;
  }
}

.detail__form__input_message {
  height: 140px;
  padding-top: 16px;
  resize: none;
}

.detail__form__btn {
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: $greenBackground;
  height: 48px;
  color: $white;
  font-family: $fontFamily;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  transition: opacity .3s;
}

.detail__form__btn:hover {
  opacity: .8;
}

.subscribe__error_message {
  transition: opacity .5s ease 0s;
  color: $error;
  font-family: $fontFamily;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  margin-top: 6px;
  display: block;
  position: absolute;
  bottom: -20px;
}

.detail__form__input_invalid {
  border: 1px solid $error;
  outline-color: $error;
}
