@import "styles/_variables.scss";

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.icon {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.success {
        background-color: $greenBackground;
    }
    &.error {
        background-color: $error;
    }
}

.success__image{
    width: 32px;
    height: 24px;
}

.success__title{
    color: $mainBlack;
    font-family: $fontFamily;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    margin: 24px 0 8px 0;
}

.success__text{
    color: $blogSecondText;
    font-family: $fontFamily;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
}
