//@import "fonts";
@import "styles/_variables.scss";

// TODO: This prevens font boosting. Need to be rechecked.
html {
  height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;

  html * {
    max-height: 1000000px;
  }
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, wp {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

body {
  -webkit-overflow-scrolling: touch !important;
  webkit-overflow-scrolling: touch !important;
  overflow-x: hidden;
}

// styles for the root captcha div
.grecaptcha-badge {
  z-index: 5;
}
