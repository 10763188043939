@import "styles/_variables.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 56px 22px 16px;

  @media(max-width: 375px) {
    padding: 48px 16px 16px;
  }

  .title {
    color: $mainText;
    font-family: $fontFamily;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 42px;
  }
}

.content {
  width: 100%;
  margin: 0 auto;
  padding: 0 22px 20px;

  @media(max-width: 375px) {
    padding: 0 16px;
  }
}
