@import "styles/_variables.scss";

.section {
    width: 100%;
    padding: 80px 0 120px 0;
}

.container {
    margin: 0 auto;
    max-width: 620px;

    @media(max-width: $mobilePoint) {
        max-width: 100%;
        padding: 0 20px;
    }

    &__title {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 150%;
        text-align: center;
        color: $mainText;
        margin-bottom: 12px;
    }

    &__text {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: $mainText;
        text-align: center;
        margin-bottom: 32px;
    }

    &__link {
        color: $greenBackground;
        transition: all .1s linear;
        font-weight: 500;

        &:hover {
            border-bottom: 1.4px solid $greenBackground;
            cursor: pointer;
        }
    }

    &__image {
        width: 100%;
        height: 200px;
        position: relative;
        margin-bottom: 32px;
        @media(max-width: $mobilePoint) {
            height: 114px;
        }
        img {
            object-fit: contain;
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
    }
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 24px;
    border: 1px solid $bloghrColor;
    border-radius: 4px;
    width: 300px;
    height: 76px;
    transition: all .3s linear;
    @media(max-width: $mobilePoint) {
        width: 100%;
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.05, 1.05);
    }

    &__content {
        display: flex;
        align-items: center;
    }

    &__title {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 162%;
        color: $mainText;
        margin-left: 16px;
    }
}